.timeline {
    display: flex;
    flex-direction: column;
}

.container-status {
    display: flex;
}

.container-color{
    display: flex;
    gap: 20px;
}

.container-circle{
    display: flex;
    padding-right: 40px;
}

.line {
    height: 1px;
    background-color: gray;
}

.m-left {
    margin-left: 5px;
}

.m-right {
    margin-right: 5px;
}

.container-line {
    display: flex;
    align-items: center;
    justify-content: center;
}

p {
    text-align: center;
}

.d-block {
    display: block;
}

.circle {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: rgb(154, 154, 154);
}