.responsiveContent {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.2%;
    margin-bottom: 20px;
  }
.responsiveContent iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.gif {
  width: 80px;
}