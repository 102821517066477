

.content-term {
    width: 100%;
    border-radius: 6px;
    background-color: #031B4E;
}

.xterm .xterm-viewport::-webkit-scrollbar-thumb {
  background-color: rgba(205, 205, 205, 0.807);    
  border-radius: 5px;
}

.xterm .xterm-viewport::-webkit-scrollbar-thumb:hover {
  background-color: rgba(152, 149, 149, 0.807);  
  transition: background-color 1s ease; 
}

.xterm .xterm-viewport::-webkit-scrollbar {
  background-color: #031B4E;
  width: 10px;      /* roundness of the scroll thumb */  /* creates padding around scroll thumb */
}
  
  #terminal {
    padding-left: 20px;
    padding-top: 20px;
  }
  
  .xterm .xterm-viewport {
      /* On OS X this is required in order for the scroll bar to appear fully opaque */
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100% !important;
      border-radius: 1%;
  }